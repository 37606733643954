
import { defineComponent } from "vue";
import ExampleBox from "@/components/Example/ExampleBox.vue";

export default defineComponent({
  name: "border-bottom-left-radius",
  components: {
    ExampleBox,
  },
});
