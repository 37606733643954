
import { defineComponent } from "vue";
import _ from "lodash";
import { css } from "mdn-data";
import Demo from "@/components/Demo.vue";
import { MdnData } from "./mdn-data";

export default defineComponent({
  name: "Home",
  components: {
    Demo,
  },
  data() {
    return {
      properties: css.properties as MdnData,
    };
  },
  computed: {
    cssPropertiesFiltered(): MdnData {
      // https://github.com/mdn/yari/blob/main/kumascript/macros/CSSAnimatedProperties.ejs
      // tallied with mdn list of animatable properties, but want to further filter
      const picked = _.pickBy(this.properties, (property, value) => {
        if (
          property.animationType !== "notAnimatable" &&
          property.animationType !== "discrete" &&
          property.status === "standard" && // only want standard
          value.charAt(0) !== "-" // avoid vendor prefixes
        ) {
          return property;
        }
      });
      return picked;
    },
  },
});
