
// https://stackoverflow.com/questions/65378635/vue-3-use-dynamic-component-with-dynamic-imports
import { defineAsyncComponent, defineComponent, VNode } from "vue";
import pascalcase from "pascalcase";

const requireContext = require.context(
  "@/components/CssProperty",
  true,
  /\.vue$/i,
  "sync"
);

const componentNames = requireContext
  .keys()
  .map((file) => file.replace(/(^.\/)|(\.vue$)/g, ""));

interface CssPropertyType {
  [key: string]: VNode;
}

const components: CssPropertyType = {};

componentNames.forEach((component) => {
  components[component] = defineAsyncComponent(() =>
    import("@/components/CssProperty/" + component + ".vue")
  );
});

export default defineComponent({
  name: "Demo",
  props: {
    name: String,
    data: Object,
    demoIndex: Number,
  },
  components,
  data() {
    return {
      componentNames,
      isExpand: false,
    };
  },
  methods: {
    toggleData() {
      this.isExpand = !this.isExpand;
    },
    pascalCase(str: string) {
      return pascalcase(str);
    },
  },
});
