
import { defineComponent } from "vue";
import ExampleImage from "@/components/Example/ExampleImage.vue";

export default defineComponent({
  name: "backdrop-filter",
  components: {
    ExampleImage,
  },
});
