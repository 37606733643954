
import { defineComponent } from "vue";
import ExampleImage from "@/components/Example/ExampleImage.vue";

export default defineComponent({
  name: "clip-path",
  components: {
    ExampleImage,
  },
});
